
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Employee from "./Employee.js"
import EmployeeMap from "../employee/EmployeeMap.js";

export default class EmployeesDomain extends RcDomainObject {
  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }

  list() {
    return this.storage.list;
  }

  findByCompany(companyId) {
    var employees = this.list();
    var found = new EmployeeMap(this.domain);
    for (var i = 0; i < employees.length; i++) {
      var item = employees[i];
      var employee = new Employee(this.domain, item);
      if (employee.company().id() === companyId) {
        found.add(employee);
      }
    }
    return found;
  }
  
  findById(employeeId) {
    var employees = this.list();
    for (var i = 0; i < employees.length; i++) {
      var item = employees[i];
      if (super.toId(item) === employeeId) {
        return new Employee(this.domain, item);
      }
    }
    return new Employee(this.domain);
  }
  
  findByUserId(userId) {
    var employees = this.list();
    var found = new EmployeeMap(this.domain);
    
    for (var i = 0; i < employees.length; i++) {
      var item = employees[i];
      var itemId = super.toId(item.User);
      if (itemId === userId) {
        var employee = new Employee(this.domain, item);
        found.add(employee);
      }
    }

    return found;
  }
}